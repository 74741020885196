
























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineELN1v2Objectives1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        objectives1Answer: null,
      },
      questions: [
        {
          text: 'Describe the purpose of the experimental work, the general method to be used and the anticipated results.',
          inputName: 'objectives1Answer',
          maxInputLength: 750,
        },
      ],
    };
  },
});
